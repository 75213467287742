<template>
  <div id="addNotification" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >PREGUNTAS DE CLIMA LABORAL</v-row
      >
      <v-container fluid class="addForms" style="margin-top: 7%">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" class="ml-5 formularios">
            <v-row>
              <!--Razon Social-->
              <label for="reason">Razón social</label>
              <select
                name="reason"
                id="reason"
                v-model="enterprise"
                @change="obtenerAreasPuestos()"
              >
                <option selected disabled value="0">Selecciona Razon</option>
                <option
                  v-for="reason in enterprises"
                  :key="reason.id"
                  v-bind:value="reason.id"
                >
                  {{ reason.razonSocial }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Area-->
              <label for="area">Área</label>
              <select name="area" id="area" v-model="area">
                <option selected disabled value="0">Selecciona Area</option>
                <option
                  v-for="area in areas"
                  :key="area.id"
                  v-bind:value="area.id"
                >
                  {{ area.nombreArea }}
                </option>
              </select>
            </v-row>
            <!--<v-row>
              <label for="idQuestion">Id Pregunta</label>
              <input
                type="text"
                name="idQuestion"
                id="idQuestion"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="idQuestion"
                maxlength="5"
                @keypress="alfaNumerico($event)"
                readonly
              />
            </v-row>-->
            <v-row>
              <!--Envio-->
              <label for="question">Pregunta</label>
              <input
                type="text"
                name="question"
                id="question"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="question"
                maxlength="40"
                @keypress="alfaNumerico($event)"
              />
            </v-row>
            <v-row>
              <!--Redacto-->
              <label for="person">Redacto</label>
              <input
                type="text"
                name="person"
                id="person"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="person"
                maxlength="20"
                @keypress="alfaNumerico($event)"
              />
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="7" align-self="center">
            <!--Acciones-->
            <button class="botonAmarillo mt-5 ml-6" @click="validacion()">
              Guardar
            </button>
            <router-link to="/questions" id="backQuestion">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Preguntas Clima Laboral</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar la pregunta?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Preguntas Clima Laboral</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="
              confirmation = false;
              id = '';
            "
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      enterprises: [],
      areas: [],
      person: "",
      enterprise: localStorage.empresaIdGlobal,
      area: 0,
      person: "",
      idQuestion: 0,
      question: "",
    };
  },
  methods: {
    guardar() {
      /*
      axios
        .post(Server + "/preguntas", {
          idRazon: this.reason
        })
        .then(response => {
          console.log(response);
          this.enterprise = "";
          this.area = 0;
          this.idQuestion = 0;
          this.question = "";
          this.person="";
          this.respuesta = "La pregunta fue guardada con éxito";
          this.confirmation = true;
        })
        .catch(e => {
          console.log(e);
          this.reason = "";
          this.area = 0;
          this.idQuestion = 0;
          this.question = "";
          this.person="";
          this.respuesta = "La pregunta no fue almacenada";
          this.confirmation = true;
        });
        */
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function () {
      this.errors = [];
      if (this.enterprise == "" || this.enterprise == " ") {
        this.errors.push("Se debe seleccionar la razón social.");
      }
      if (this.area == "" || this.area == " ") {
        this.errors.push("Se debe seleccionar el area.");
      }
      /*if (this.idQuestion == "" || this.idQuestion == " " || this.idQuestion==0) {
        this.errors.push("Se debe especificar el id de la Pregunta.");
      }*/
      if (this.question == "" || this.question == " ") {
        this.errors.push("Se debe especificar la Pregunta.");
      }
      if (this.person == "" || this.person == " ") {
        this.errors.push("Se debe especificar quien Redacto.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    aux() {
      this.advice = false;
      this.enterprise = 0;
      this.area = 0;
      this.idQuestion = 0;
      this.question = 0;
      this.person = 0;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerAreasPuestos() {
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.obtenerAreasPuestos();
  },
};
</script>